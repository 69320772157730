<template>
    <div class="ToolClassList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceKeepFram" ref="deviceKeepFram">
                    <el-form-item label="仪器名称">
                        <el-input v-model="deviceKeepFram.realName" placeholder="请输入仪器名称查询"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="addDeviceKeep"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceKeepList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="code" label="编号" align="center"> </el-table-column>
                <el-table-column prop="realName" label="仪器名称" align="center">
                </el-table-column>
                <el-table-column prop="standard" label="标准配置" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改"
                            @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini"  title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceKeepFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="deviceKeepFram.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
         <!-- 新增表单 -->
         <el-dialog :title="state ? '添加仪器仪表名称' : '修改仪器仪表名称'" :visible.sync="dialogFormVisible" width="500px"  :before-close="closeInfo" :close-on-click-modal="false">
            <el-form :model="deviceKeepInfo" :rules="rules" ref="deviceKeepInfo">
                <el-form-item label="编号" :label-width="formLabelWidth" prop="code" v-show="!state">
                <el-input v-model="deviceKeepInfo.code" autocomplete="off" :readonly="!state"></el-input>
                </el-form-item>
                <el-form-item label="仪器名称" :label-width="formLabelWidth" prop="realName">
                <el-input v-model="deviceKeepInfo.realName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="标准配置" :label-width="formLabelWidth" prop="standard">
                <el-input v-model="deviceKeepInfo.standard" autocomplete="off" type="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('deviceKeepInfo')">取 消</el-button>
                <el-button  class="btn" @click="sure('deviceKeepInfo')"
                >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { serchDeviceKeep, delDeviceKeep, addDeviceKeep, updateDeviceKeep } from '@/api/deviceKeep.js'
export default {
    data(){
        return {
            deviceKeepList: [],
            deviceKeepFram: {
                realName: null,
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            deviceKeepInfo: {
                id: null,
                code: null,
                realName: null,
                standard: 0
            },
            rules: {
                spareName: [{required: true, message: '请输入附件名称'}],
            },
            formLabelWidth: '80px',
            dialogFormVisible: false,
            state: false
        }
    },
    mounted(){
        this.loadDeviceKeep();
    },
    methods: {
        handleSizeChange(val) {
            this.deviceKeepFram.pageSize = val;
            this.deviceKeepFram.pageNum = 1;
            this.loadDeviceKeep();
        },
        handleCurrentChange(val) {
            this.deviceKeepFram.pageNum = val;
            this.loadDeviceKeep();
        },

        loadDeviceKeep(){
            serchDeviceKeep(this.deviceKeepFram).then(res => {
                if (res.code === '000000') {
                    this.deviceKeepList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.deviceKeepList.forEach((item) => {
                        item.seq = count++
                    })
                }
            })
        },
        //新增
        addDeviceKeep(){
            this.deviceKeepInfo = {
                id: null,
                code: null,
                realName: null,
                standard: 0
            },
            this.dialogFormVisible = true
            this.state=true
        },
        // 修改
        handleEdit(row){
            this.state = false;
            this.dialogFormVisible = true;
            //不要将当前行的值直接赋值给表单
            // this.form = row;
            this.deviceKeepInfo = {...row};
        },

        //提交
        sure(deviceKeepInfo) {
            this.$refs[deviceKeepInfo].validate(valid => {
                if(valid) {
                    if(this.state){
                        //新增
                        addDeviceKeep(this.deviceKeepInfo).then(res => {
                            if(res.code === '000000'){
                                this.$message.success('添加成功')
                            }

                            this.$refs[deviceKeepInfo].resetFields()
                            this.dialogFormVisible = false
                           
                            this.loadDeviceKeep()
                        })
                    }else{
                        //修改
                        updateDeviceKeep(this.deviceKeepInfo).then(res => {
                            if(res.code === '000000'){
                                this.$message.success('修改成功')
                            }
                            this.$refs[deviceKeepInfo].resetFields()
                            this.dialogFormVisible = false
                            this.loadDeviceKeep()
                        })

                    }
                
                }
            })
        },

        //关闭
        closeInfo() {
            this.$refs['deviceKeepInfo'].resetFields();
            this.dialogFormVisible = false;
        },
        
        //删除
        del({id,realName}) {
            this.$confirm('是否删除名称为“'+realName+'”的仪器名称？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delDeviceKeep(id).then(res => {
                if (res.code === '000000') {
                    this.$message.success('删除成功')
                    this.loadDeviceKeep()
                }
                })
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消删除'
                })
            })

        },


         //搜索
         handleSearch(){
            this.loadDeviceKeep(this.deviceKeepFram);
        },
        //重置
        handleReset(){
            this.deviceKeepFram = {};
            this.loadDeviceKeep(this.deviceKeepFram);
        }


    }
}

</script>

<style lang="scss" scoped>
.ToolClassList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>